import axios from 'axios';
import { InterfaceServicesApiResponse } from 'src/api/types';
import { urlInterfaceApiFromLocalStorage } from 'src/api/utilis';
import { ResultCodes } from './taxAuthUtils';

export const taxAuthApi = {
  init: async (body: { guid: string }, urlInterfaceApiParam: string): Promise<InterfaceServicesApiResponse<string>> => {
        const r = await axios.post(`${urlInterfaceApiParam}/IsClientGuidValid`, body);
    return r.data;
  },
  setCustomerTokenFromTaxAuthority: async (body: {
    customerCode: string;
    guid: string;
    userUUID: string;
    token: string; // CLIENT ID
  }): Promise<InterfaceServicesApiResponse<any>> => {
      const r = await axios.post(`${urlInterfaceApiFromLocalStorage}/SetCustomerTokenFromTaxAuthority`, body);
    return r.data;
  },
  resendMail: async (body: {
    guid: string;
    userGuid: string;
  }): Promise<
    InterfaceServicesApiResponse<{
      totalMailsSendSuccessfully: number;
      totalRecipients: number;
    }>
  > => {
      const r = await axios.post(`${urlInterfaceApiFromLocalStorage}/ResendAuthorizationEmailToUser`, body);
    return r.data;
  },
  getApiVer: async (urlInterfaceApi: string): Promise<InterfaceServicesApiResponse<string>> => {
      const r = await axios.post(`${urlInterfaceApi}/GetVersion`, {});
    return r.data;
  },
};
