import React from 'react';
import { BaseCard, CardTitle } from 'src/components/BaseCard';
import { texts } from '../taxAuthUtils';
import { SuccessImg } from './SuccessImg';

export const TaxAuthSuccess: React.FC<{}> = () => {
  return (
    <BaseCard style={{}}>
      <div style={{}}>
        <CardTitle style={{ paddingBottom: 0 }}>{texts.completionMessage1}</CardTitle>
        <CardTitle style={{ paddingBottom: 0 }}>{texts.completionMessage2}</CardTitle>
        <CardTitle>{texts.completionMessage3}</CardTitle>
      </div>
      <SuccessImg />
      <div></div>
    </BaseCard>
  );
};
