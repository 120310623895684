import { useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { URL_INTERFACE_API_KEY } from 'src/screens/TaxAuthScreen/TaxAuthScreen';

const useGetInterfaceUrl = () => {
    const [searchParams] = useSearchParams();
    const urlInterfaceApiParam  = searchParams.get(URL_INTERFACE_API_KEY) || '';
    const urlInterfaceApiFromLocalStorage  = localStorage.getItem(URL_INTERFACE_API_KEY) || '';
    const url = urlInterfaceApiParam || urlInterfaceApiFromLocalStorage || '';

    return url;
};

export default useGetInterfaceUrl;
