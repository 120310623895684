export const STATE_LS_KEY = 'taxAuthState';

export enum ResultCodes {
  Success = 0,
  ExpiredUrl = 7,
  InvalidGuid = 6,
  DalUpdateTaxAuthCustomerTokenFailed = 1,
  CantGetRecipientByUserGuid = 5,
  AllEmailRequestsFailed = 8,
  MissingUrlParams = 601,
  UnknownException = 602,
  InvalidApiResponseObj = 603,
}

export enum Stages {
  Loading = 1,
  Success = 2,
  Failure = 3,
}

const SUPPORT_NUM = '08-9464288';

const createText = (text: string, withSupportNum?: boolean): string => {
  if (withSupportNum) {
    return `${text} - ניתן ליצור קשר עם מוקד התמיכה בטלפון <br>${SUPPORT_NUM}`;
  }
  return text;
};

export const texts = {
  failedToResendMail: createText('שגיאה בשליחת מייל חוזר', true),
  serverConnectionBlock: createText('קיימת חסימת תקשורת לשרת', true),
  allEmailRequestsFailed: createText('שליחת מייל נכשלה', true),
  invalidUrl: createText('חסרים פרמטרים בקישור הכניסה לדף הנחיתה', true),
  invalidGuid: createText('קישור הכניסה לדף נחיתה אינו תקין', true),
  expiredUrl: 'קישור הכניסה לדף נחיתה כבר אינו בתוקף',
  unknownError: `אירעה שגיאה , אנא נסה שנית או פנה למוקד התמיכה בטלפון <br>${SUPPORT_NUM}`,
  newMailSentSuccessfully: 'מייל נשלח בהצלחה, יש להיכנס למערכת דרך הקישור החדש ',
  resendMail: 'לחץ לשליחת מייל חוזר',
  completionMessage1: 'זהו סיימנו!',
  completionMessage2: 'התהליך הסתיים בהצלחה',
  completionMessage3: 'ניתן לחזור כעת למערכת ולהפיק חשבוניות',
} as const;

const codeToTexts: Record<number, string> = {
  [ResultCodes.DalUpdateTaxAuthCustomerTokenFailed]: texts.unknownError,
  [ResultCodes.CantGetRecipientByUserGuid]: `המשתמש לא זוהה במערכת, אנא פנה למוקד התמיכה בטלפון ${SUPPORT_NUM}`,
  [ResultCodes.AllEmailRequestsFailed]: texts.failedToResendMail,
};

export const getErrorText = (code: number): string => {
  return codeToTexts[code] || texts.unknownError;
};
