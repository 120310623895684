import { AxiosResponse } from 'axios';
import React from 'react';
import { InterfaceServicesApiResponse } from 'src/api/types';
import { useDispatchAlert } from 'src/hooks/useAlert';

export const useApiHandler = () => {
  const dispatchAlert = useDispatchAlert();
  const [loading, setLoading] = React.useState(false);

  const handleCall = async <T>(
    cb: () => Promise<InterfaceServicesApiResponse<T>>
  ): Promise<InterfaceServicesApiResponse<T>> => {
    try {
      setLoading(true);
      const res = await cb();

      if (!('isSuccess' in res)) {
        return {
          isSuccess: false,
          code: 99,
          data: null,
          message: `Invalid response data structure \n\n  ${JSON.stringify(res.data)}`,
        };
      }

      return res;
    } catch (error: any) {
      // if is 401, return appropriate msg
      if (error.response?.status === 401) {
        return { isSuccess: false, code: 401, data: null, message: error.response.data };
      }

      return { isSuccess: false, code: 99, data: null, message: error.message };
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleCall,
  };
};
