import React from 'react';
import styled from 'styled-components';
import Header from 'src/components/Header';
import InfoFooter from 'src/components/InfoFooter';
import { heights } from 'src/styles/commonStyles';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { taxAuthApi } from './taxAuthApi';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { FailureCard } from './components/FailureCard';
import { TaxAuthSuccess } from './components/TaxAuthSuccess';
import { LoadingCard } from './components/LoadingCard';
import { authTaxRootSelector, taxAuthSliceActions } from 'src/store/slices/taxAuthSlice';
import { ResultCodes, Stages, STATE_LS_KEY } from './taxAuthUtils';
import { useEffectTrigger } from 'src/hooks/useEffectTrigger';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useApiHandler } from './useApi';
import moment from 'moment';
import { useOnMountEffect } from 'src/hooks/useOnMountEffect';

const { innerHeight } = window;

export const CLIENT_ID_KEY = 'clientId'; // tax system user id
export const GUID_KEY = 'guid'; // client guid
export const USER_UUID_KEY = 'userUuid'; // client guid
export const URL_AUTH_KEY = 'UrlAuth'; // client guid
export const URL_INTERFACE_API_KEY = 'UrlInterfaceApi'; // client guid

// export const ENV_KEY = 'env';

export const TaxAuthScreen: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const dispatchAlert = useDispatchAlert();
  const apiHandler = useApiHandler();

  const state = useAppSelector((state) => authTaxRootSelector(state));
  const isMobile = useAppSelector((state) => isMobileSelector(state));
  const urlAuthParam  = searchParams.get(URL_AUTH_KEY) || '';

  const clientId = localStorage.getItem(CLIENT_ID_KEY) || '';

  const aElementRef = React.useRef<HTMLAnchorElement>(null);

  const linkElementClickTrigger = useEffectTrigger(() => {
    aElementRef.current?.click();
  });

  const getStageCard = () => {
    if (state.currStage === Stages.Loading) return <LoadingCard />;
    if (state.currStage === Stages.Success) return <TaxAuthSuccess />;
    if (state.currStage === Stages.Failure) return <FailureCard />;
  };

  const handleFailure = (code: number) => {
    dispatch(taxAuthSliceActions.setValue({ key: 'errCode', value: code }));
    dispatch(taxAuthSliceActions.setStage(Stages.Failure));
  };

  useOnMountEffect(() => {
    const asyncHandler = async () => {
      // Local storage vars
      const guidFromLocalStorage = localStorage.getItem(GUID_KEY) || '';
      const clientIdFromLocalStorage = localStorage.getItem(CLIENT_ID_KEY) || '';
      const userUuidFromLocalStorage = localStorage.getItem(USER_UUID_KEY) || '';
      const urlAuthFromLocalStorage  = localStorage.getItem(URL_AUTH_KEY) || '';
      const urlInterfaceApiFromLocalStorage  = localStorage.getItem(URL_INTERFACE_API_KEY) || '';
      
      // Params
      const returnedCodeParam = searchParams.get('code') || '';
      const clientIdParam = searchParams.get(CLIENT_ID_KEY) || '';
      const guidParam = searchParams.get(GUID_KEY) || '';
      const userUuidParam = searchParams.get(USER_UUID_KEY) || '';
      const urlAuthParam  = searchParams.get(URL_AUTH_KEY) || '';
      const urlInterfaceApiParam  = searchParams.get(URL_INTERFACE_API_KEY) || '';
      
      // ------------------------------------- returning to page after redirect
      if (returnedCodeParam) {
       
        const res = await apiHandler.handleCall(() =>
          taxAuthApi.setCustomerTokenFromTaxAuthority({
            customerCode: returnedCodeParam,
            guid: guidFromLocalStorage,
            userUUID: userUuidFromLocalStorage,
            token: clientIdFromLocalStorage,
          })
        );

        // success
        if (res.isSuccess) {
          dispatch(taxAuthSliceActions.setStage(Stages.Success));
          //localStorage.clear();
          return;
        }

        // failure
        handleFailure(res.code);
        return;
      }

      // ------------------------------------ new page
      // invalid url
      
      if (!guidParam || !clientIdParam || !urlAuthParam || !urlInterfaceApiParam) {
        handleFailure(ResultCodes.MissingUrlParams);
        return;
      }

      // valid url
      //localStorage.clear();
      
      localStorage.setItem(GUID_KEY, guidParam);
      localStorage.setItem(CLIENT_ID_KEY, clientIdParam);
      localStorage.setItem(USER_UUID_KEY, userUuidParam || '');
      localStorage.setItem(URL_INTERFACE_API_KEY, urlInterfaceApiParam);
      localStorage.setItem(URL_AUTH_KEY, urlAuthParam);

      try {
        const res = await taxAuthApi.init({ guid: guidParam }, urlInterfaceApiParam);

        if (!res.isSuccess) {
          handleFailure(res.code);
          return;
        }

        // todo
        // handleFailure(ResultCodes.ExpiredUrl);
        // return;

        // success
        linkElementClickTrigger.trigger();
        return;
      } catch (error) {
        handleFailure(ResultCodes.UnknownException);
        console.log(error);
      }
    };

    asyncHandler();
  });

  return (
    <Cont>
      <Header />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: isMobile ? 'flex-start' : 'center',
          marginTop: isMobile ? '20px' : '0px',
          height: `calc(${innerHeight}px - ${heights.header}px - ${heights.infoFooter}px)`,
        }}
      >
        {getStageCard()}
      </div>
      <a
        ref={aElementRef}
        style={{ display: 'none' }}
        href={`${urlAuthParam}?response_type=code&client_id=${clientId}&scope=scope`}
      ></a>
      <InfoFooter />
    </Cont>
  );
};

const Cont = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: ${innerHeight}px !important;
  min-height: ${innerHeight}px !important;
  min-width: 300px;
  overflow: hidden;
`;
