import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsRTL } from 'src/hooks/useIsRTL';
import { useOnMountEffect as useOnMountEffect } from 'src/hooks/useOnMountEffect';
import { taxAuthApi } from 'src/screens/TaxAuthScreen/taxAuthApi';
import { useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { heights } from 'src/styles/commonStyles';
import { useA11yTheme } from 'src/styles/useA11yTheme';
import styled from 'styled-components';
import yitLogo from '../images/yitLogo.png';
import useGetInterfaceUrl from 'src/hooks/useGetInterfaceUrl';

interface Props {}

const InfoFooter: FC<Props> = ({}) => {
  const { t } = useTranslation();

  const isRTL = useIsRTL();

  const isMobile = useAppSelector((state) => isMobileSelector(state));
  const { theme } = useA11yTheme();
  const [apiVer, setApiVer] = React.useState('loading');
  const interfaceUrl = useGetInterfaceUrl();

  useOnMountEffect(() => {
    const asyncHandler = async () => {
      try {
        const res = await taxAuthApi.getApiVer(interfaceUrl);

        if (res.isSuccess && res.data) {
          setApiVer(res.data);
          return;
        }

        setApiVer('ERROR');
      } catch (error) {
        console.log(error);
      }
    };

    asyncHandler();
  });

  return (
    <InfoFooterDiv>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <YitLogo />
        <div style={{ display: 'flex', gap: '20px', fontSize: '12px' }}>
          <a href="https://www.y-it.co.il/" target="_blank" rel="noreferrer">
            מי אנחנו
          </a>
          <a
            href="https://www.y-it.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8"
            target="_blank"
            rel="noreferrer"
          >
            יצירת קשר ותמיכה
          </a>
          <a href="https://www.y-it.co.il/products/" target="_blank" rel="noreferrer">
            מוצרים נוספים
          </a>
        </div>
      </div>

      <div
        style={{
          fontSize: '10px',
          paddingLeft: '10px',
          fontWeight: 400,
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <div style={{ color: '#494949' }}>{process.env.REACT_APP_VER}</div>
        <div style={{ color: '#494949' }}>Ver {apiVer}</div>
      </div>
    </InfoFooterDiv>
  );
};

const InfoFooterDiv = styled.div<{}>`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  height: ${heights.infoFooter}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
`;

export const YitLogo: React.FC<{
  style?: React.CSSProperties;
}> = ({ style = {} }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${yitLogo})`,
        width: '50px',
        height: '20px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...style,
      }}
    />
  );
};

export default InfoFooter;
